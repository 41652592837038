import area from '@turf/area';
import type { TaskingRequest } from '_api/tasking/types';
import classNames from 'classnames';
import moment from 'moment';
import { Icon } from 'opencosmos-ui';
import type { IconName } from 'opencosmos-ui/src/icons/Icon';
import findSatelliteNameByMissionId from 'utils/findSatelliteNameByMissionId';

type IProps = {
  taskingRequest: TaskingRequest;
  onClick: () => void;
  active?: boolean;
};

const failedStatuses = ['FAILED', 'EXPIRED', 'CANCELLED'];
const scheduledStatuses = [
  'PENDING',
  'CONFIRMED',
  'SCHEDULED',
  'ACQUIRED',
  'PROCESSING',
  'APPROVED',
];
const completedStatuses = ['COMPLETED'];

const TaskingCard = (props: IProps) => {
  const tr = props.taskingRequest;

  let satellites = tr.instruments
    .map((instrument) => findSatelliteNameByMissionId(instrument.mission_id))
    .filter((s) => s !== undefined) as string[];

  satellites = [...new Set(satellites)];

  const completedActivities = tr.activities.filter((activity) =>
    completedStatuses.includes(activity.status)
  );
  const scheduledActivities = tr.activities.filter((activity) =>
    scheduledStatuses.includes(activity.status)
  );
  const failedActivities = tr.activities.filter((activity) =>
    failedStatuses.includes(activity.status)
  );

  const startDate = tr.constraints.find(
    (aqDate) => aqDate.type === 'ACQUISITION_DATE'
  )?.min;
  const endDate = tr.constraints.find(
    (aqDate) => aqDate.type === 'ACQUISITION_DATE'
  )?.max;

  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      className={classNames(
        'bg-item dark:bg-item-dark text-item-contrast dark:text-item-dark-contrast hover:bg-accent-900 dark:hover:bg-item-dark-hover dark:hover:text-item-dark-contrast max-w-sm min-w-[230px] cursor-pointer',
        {
          'dark:bg-item-dark-hover bg-accent-900 dark:text-item-dark-contrast':
            props.active,
        }
      )}
      data-testid="tasking-request-card"
    >
      <div className="py-3" data-testid="tasking-request-info">
        <h2 className="px-5 m-0">{tr.project_name ?? 'Image Acquisition'}</h2>

        <Region
          name={tr.region_name}
          areaKm2={Number((area(tr.region) / 1000000).toFixed(2))}
        />
        <Satellites satellites={satellites} />
        <Duration
          start={
            startDate
              ? moment.unix(startDate as number).format('YYYY-MM-DD')
              : 'N/A'
          }
          end={
            endDate
              ? moment.unix(endDate as number).format('YYYY-MM-DD')
              : 'N/A'
          }
        />
        {/* TODO: Enable this when subject becomes available on the backend */}
        {/* <Assignee subject="" /> */}
      </div>
      <div className="flex" data-testid="tasking-request-counters">
        <Status
          status="completed"
          counter={completedActivities.length}
          className="bg-success text-item-contrast "
        />
        <Status
          status="scheduled"
          counter={scheduledActivities.length}
          className="bg-text text-item-contrast dark:text-item-dark-contrast"
        />
        <Status
          status="failed"
          counter={failedActivities.length}
          className="bg-warning text-item-contrast "
        />
      </div>
    </div>
  );
};

type CardItemProps = {
  icon: IconName;
  children: React.ReactNode;
};

const CardItem = (props: CardItemProps) => {
  return (
    <div className="flex px-5 my-1 items-baseline">
      <div className="pr-5 flex self-center">
        <Icon icon={props.icon} />
      </div>
      {props.children}
    </div>
  );
};
// TODO: Uncomment this when subject becomes available in BE
// type AssigneeProps = {
//   subject: string;
// };

// const Assignee = (props: AssigneeProps) => {
//   return (
//     <CardItem icon="Person">
//       <span>{props.subject}</span>
//     </CardItem>
//   );
// };

type SatellitesProps = {
  satellites: string[];
};

const Satellites = (props: SatellitesProps) => {
  return (
    <CardItem icon="Satellite">
      <ul className="list-none p-0 m-0">
        {props.satellites.map((satellite) => (
          <li key={satellite}>{satellite}</li>
        ))}
      </ul>
    </CardItem>
  );
};

type RegionProps = {
  name: string;
  areaKm2: number;
};

const Region = (props: RegionProps) => {
  return (
    <CardItem icon="AoiAdd">
      <span className="flex-1">{props.name}</span>
      <span>
        {props.areaKm2} km<sup>2</sup>
      </span>
    </CardItem>
  );
};

type DurationProps = {
  start: string;
  end: string;
};

const Duration = (props: DurationProps) => {
  return (
    <CardItem icon="Clock">
      <span className="flex-1">{props.start}</span>
      <span>{props.end}</span>
    </CardItem>
  );
};

type StatusProps = {
  status: string;
  counter: number;
  className?: string;
};

const Status = (props: StatusProps) => {
  return (
    <div
      className={
        'flex flex-1 flex-col p-1 items-center min-w-0 ' +
        (props.className ?? '')
      }
    >
      <span className="uppercase text-xs">{props.status}</span>
      <span className="text-xl">{props.counter}</span>
    </div>
  );
};

export default TaskingCard;
