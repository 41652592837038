import type { EmptyParams } from '_api/client';
import { hosts } from '_api/hosts';
import type {
  TaskingRequest,
  TaskingRequestFeature,
  RequestStatus,
  ManualOpportunity,
  Swath,
  TaskingRequestConstraint,
  TaskingRequestInstrument,
  TaskingRequests,
  TaskingSatellites,
  TaskingRequestObjective,
} from '_api/tasking/types';
import type { SensorId } from '_api/sensors/types';
import type { SatelliteId } from '_api/satellites/types';
import { toaster } from 'toaster';
import { apiFetchHandler } from '_api/clientFetch';
import { clientTranslate } from 'utils/hooks/useLocalisation';
import { formatBaseURL } from 'datacosmos/utils/utils';
import type { ActivityParameters } from '_api/activities/types';
import type { OverviewFilters } from 'pages/datacosmos/tasking_overview/useTaskingOverviewData';

export * from '_api/tasking/types';

export type PostTaskingRequestParams = {
  projectId: string;
};

export type TaskingRequestType = 'MANUAL' | 'AUTOMATED';
export type TaskingRequestPriority = 40 | 50;

export type PostActivity = {
  start_date: string;
  end_date: string;
  mission_id: string;
  type: 'IMAGE_ACQUISITION';
  priority?: number;
  parameters: ActivityParameters;
};

export type PostTaskingRequestBody = {
  type: TaskingRequestType;
  region_name: string;
  region: TaskingRequestFeature;
  parameters?: {
    objectives?: TaskingRequestObjective[];
    priority?: TaskingRequestPriority;
  };
  activities?: PostActivity[];
  notes: string;
  constraints: TaskingRequestConstraint[];
  instruments: TaskingRequestInstrument[];
};

export type PostTaskingRequestData = {
  id: string;
  msg: string;
  order_id: string;
  checkout_redirect_url: string;
};

export const postTaskingRequest = apiFetchHandler<
  PostTaskingRequestData,
  PostTaskingRequestParams,
  PostTaskingRequestBody
>({
  host: hosts.data.v1,
  endpoint: ({ projectId }) => `/projects/${projectId}/tasking/requests`,
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotCreate'),
  onSuccess: (data) => {
    toaster.show({
      message: data?.msg!,
      intent: 'success',
    });
  },
  errorDescription: (e) => e.join('; '),
});

export type GetTaskingRequestParams = {
  requestId: string;
};

export const getTaskingRequest = apiFetchHandler<
  TaskingRequest,
  GetTaskingRequestParams
>({
  host: hosts.data.v1,
  endpoint: ({ requestId }) => `/tasking/requests/${requestId}`,
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGet'),
  errorDescription: (e) => e.join('; '),
});

export type PatchTaskingRequestParams = {
  requestId: string;
};

export type PatchTaskingRequestActivityBody = {
  id: string;
  start_date?: string;
  end_date?: string;
  priority?: number | undefined;
  parameters?: ActivityParameters;
  status?: RequestStatus;
};

export type PatchTaskingRequestBody = {
  status?: RequestStatus;
  status_reason?: string;
  activities?: PatchTaskingRequestActivityBody[];
};

export type PatchTaskingRequestData = {
  id: string;
  msg: string;
};

export const patchTaskingRequest = apiFetchHandler<
  PatchTaskingRequestData,
  PatchTaskingRequestParams,
  PatchTaskingRequestBody
>({
  host: hosts.data.v1,
  endpoint: ({ requestId }) => `/tasking/requests/${requestId}`,
  method: 'PATCH',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotPatch'),
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    toaster.show({
      message: data?.msg!,
      intent: 'success',
    });
  },
});

export type TaskingRequestsByProjectIdQueryParams = {
  status?: string;
  end_date?: Date | null;
  start_date?: Date | null;
};

export type GetTaskingRequestsByProjectIdParams = {
  projectId: string;
  cursor?: string;
  filters?: TaskingRequestsByProjectIdQueryParams;
};

export const getTaskingRequestsByProjectId = apiFetchHandler<
  TaskingRequests,
  GetTaskingRequestsByProjectIdParams
>({
  host: hosts.data.v1,
  endpoint: ({ projectId, cursor, filters }) => {
    const baseURL = formatBaseURL({ projectId, cursor, filters });
    return baseURL;
  },
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGetById'),
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export const getSatellitesForTasking = apiFetchHandler<TaskingSatellites>({
  host: hosts.data.v1,
  endpoint: `/tasking/satellites`,
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGet'),
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export type PostSwathSearchBody = {
  area_of_interest: {
    name: string;
    geojson: {
      type: string;
      geometry: GeoJSON.Polygon;
      properties: {};
    };
  };
  instrument: {
    mission_id: string;
    sensor_id: string;
  };
  roll_angle: number;
  start: string;
  stop: string;
  project_id: string;
};

export const postSwathSearch = apiFetchHandler<
  Swath,
  EmptyParams,
  PostSwathSearchBody
>({
  host: hosts.data.v1,
  endpoint: '/tasking/swath',
  method: 'POST',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotPost'),
  errorDescription: (e) => e.join('; '),
});

export type PostManualTaskingSearchBody = {
  instruments: {
    mission_id: SatelliteId;
    sensor_id: SensorId;
  }[];
  areas_of_interest: {
    name: string;
    geojson: GeoJSON.Feature<GeoJSON.Polygon>;
  }[];
  start: string;
  stop: string;
  constraints: {
    type: string;
    min: number;
    max: number;
  }[];
};

export const postManualTaskingSearch = apiFetchHandler<
  ManualOpportunity[],
  EmptyParams,
  PostManualTaskingSearchBody
>({
  host: hosts.data.v1,
  endpoint: '/tasking/search',
  method: 'POST',
  errorMessage: clientTranslate(
    'datacosmos.fetchErrors.tasking.cannotPostManualTasking'
  ),
  errorDescription: (e) => e.join('; '),
});

export type GetAllTaskingRequestsParams = {
  status: RequestStatus;
  cursor?: string;
  filters?: OverviewFilters;
};

export const getAllTaskingRequests = apiFetchHandler<
  TaskingRequests,
  GetAllTaskingRequestsParams
>({
  host: hosts.data.v1,
  endpoint: ({ status, cursor, filters }) => {
    let requestURL = cursor
      ? `/tasking/requests?order=descending&limit=10&cursor=${cursor}`
      : `/tasking/requests?order=descending&limit=10`;
    if (status) {
      requestURL += `&status=${status.toUpperCase()}`;
    }
    if (filters) {
      const queryParams = {
        start_date: filters.fromDate,
        end_date: filters.toDate,
        organisation: filters.organisationId,
        mission_id: filters.missionId,
        project_id: filters.projectId,
        type: filters.type,
        acquisition_start_date: filters.acquisitionStartDate,
        acquisition_end_date: filters.acquisitionEndDate,
      };

      if (queryParams.start_date) {
        requestURL += `&start_date=${queryParams.start_date.toISOString()}`;
      }
      if (queryParams.end_date) {
        requestURL += `&end_date=${queryParams.end_date.toISOString()}`;
      }
      if (queryParams.acquisition_start_date) {
        requestURL += `&acquisition_start_date=${queryParams.acquisition_start_date.toISOString()}`;
      }
      if (queryParams.acquisition_end_date) {
        requestURL += `&acquisition_end_date=${queryParams.acquisition_end_date.toISOString()}`;
      }
      if (queryParams.organisation && queryParams.organisation !== 'ALL') {
        requestURL += `&organisation=${queryParams.organisation.toString()}`;
      }
      if (queryParams.mission_id && queryParams.mission_id !== 'ALL') {
        requestURL += `&mission=${queryParams.mission_id.toString()}`;
      }
      if (queryParams.project_id && queryParams.project_id !== 'ALL') {
        requestURL += `&project=${queryParams.project_id.toString()}`;
      }
      if (
        queryParams.type &&
        (queryParams.type === 'MANUAL' || queryParams.type === 'AUTOMATED')
      ) {
        requestURL += `&type=${queryParams.type.toString()}`;
      }
    }
    return requestURL;
  },
  method: 'GET',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotGetAll'),
  errorDescription: (e) => e.join('; '),
  omitCaptureDataKey: true,
});

export type PatchActivityToTaskingRequestParams = {
  requestId: string;
};

export type PatchActivityToTaskingRequestBody = {
  tasking_request_ids: string[];
  mission_id: string;
  start_date: string;
  end_date: string;
  type: 'IMAGE_ACQUISITION';
  priority?: number;
  parameters: ActivityParameters;
};

export type PatchActivityToTaskingRequestData = {
  id: string;
  msg: string;
};

export const patchActivityToTaskingRequest = apiFetchHandler<
  PatchActivityToTaskingRequestData,
  PatchActivityToTaskingRequestParams,
  PatchActivityToTaskingRequestBody
>({
  host: hosts.data.v1,
  endpoint: ({ requestId }) => `/tasking/requests/${requestId}/activity`,
  method: 'PATCH',
  errorMessage: clientTranslate('datacosmos.fetchErrors.tasking.cannotPatch'),
  errorDescription: (e) => e.join('; '),
  onSuccess: (data) => {
    toaster.show({
      message: data?.msg!,
      intent: 'success',
    });
  },
});
